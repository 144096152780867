<template>
  <div>
    <b-row v-if="permissions.includes('vendor-inquiry')">
      <!-- card collapsible  -->
      <b-col md="12">
        <b-card-actions title="Filter" :collapsed="true" action-collapse>
          <b-row>
            <b-col cols="3">
              <b-form-group label="Name" label-for="name">
                <b-form-input
                  id="name"
                  placeholder="Name"
                  v-model="filter.name"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Category" label-for="category-vendor">
                <b-form-select
                  id="category-vendor"
                  v-model="filter.selectedCategory"
                  :options="filter.optionsCategory"
                />
                <!-- Selected: <strong>{{ filter.selectedCategory }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="mt-1"
                @click="getInquiry(1, filter.name, filter.selectedCategory)"
              >
                Search
              </b-button>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row v-if="permissions.includes('vendor-create')">
      <b-col class="text-right mb-1">
        <router-link to="/product/vendor/add">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="success"
            pill
            class="px-3"
          >
            Add Vendor
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-card v-if="permissions.includes('vendor-inquiry')">
      <!-- search input -->
      <!-- <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label class="mr-1">Search</label>
                        <b-form-input
                            v-model="searchTerm"
                            placeholder="Search"
                            type="text"
                            class="d-inline-block"
                        />
                    </div>
                </b-form-group>
            </div> -->

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <!-- <b-avatar
                            :src="props.row.avatar"
                            class="mx-1"
                        /> -->
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <!-- Column: Name -->
          <span
            v-else-if="props.column.field === 'parent_name'"
            class="text-nowrap"
          >
            <span class="text-nowrap" v-if="props.row.parent_name === ''"
              >-</span
            >
            <span class="text-nowrap" v-else>{{ props.row.parent_name }}</span>
          </span>

          <!-- Column: Location -->
          <span
            v-else-if="props.column.field === 'location'"
            class="text-nowrap"
          >
            <span class="text-nowrap"
              >{{ props.row.city }}, {{ props.row.province }},
              {{ props.row.country }}</span
            >
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-bind:to="{ path: '/product/vendor/' + props.row.id }"
                  v-if="permissions.includes('vendor-detail')"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Detail</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="deleteVendor(props.row.id)"
                  v-if="permissions.includes('vendor-delete')"
                >
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ meta.from }} to {{ meta.to }}
              </span>
              <span class="text-nowrap"
                >&nbsp;of {{ meta.total }} entries
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                  (value) =>
                    getInquiry(value, filter.name, filter.selectedCategory)
                "
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  components: {
    BCardActions,
    VueGoodTable,
    ToastificationContent,
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Category",
          field: "category_name",
        },
        {
          label: "PIC",
          field: "pic",
        },
        {
          label: "PIC Phone",
          field: "personal_phone",
        },
        {
          label: "Location",
          field: "location",
        },
        {
          label: "Action",
          field: "action",
          width: "100px",
        },
      ],
      filter: {
        name: "",
        selectedCategory: "",
        optionsCategory: [],
      },
      meta: {
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
        per_page: 10,
        total: 0,
      },
      permissions: [],
      rows: [],
      errors: "",
      errMessage: "",
      searchTerm: "",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getAllPermissions();
    this.getInquiry(1, "", "");
    this.getCategories();
  },
  methods: {
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getInquiry($page, $name, $category) {
      this.$http
        .get(
          "vendors?page=" +
            $page +
            ($name ? "&name=" + this.filter.name : "") +
            ($category ? "&category=" + this.filter.selectedCategory : "")
        )
        .then((res) => {
          this.rows = res.data.data;
          // console.log(res.data.data)
          var meta = res.data.meta;
          if (meta.from != null) {
            this.meta.from = meta.from;
          }
          if (meta.to != null) {
            this.meta.to = meta.to;
          }
          this.meta.current_page = meta.current_page;
          this.meta.last_page = meta.last_page;
          this.meta.per_page = meta.per_page;
          this.meta.total = meta.total;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    getCategories() {
      this.$http.get("vendor/categories/all").then((response) => {
        // console.log(response.data.data)
        this.filter.optionsCategory.push({ value: "", text: "All" });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          // console.log(datas[1].children.length === 0)
          if (datas[i].children.length === 0) {
            var $data = {
              value: datas[i].id,
              text: datas[i].name,
            };
            // console.log($data)
            this.filter.optionsCategory.push($data);
          } else {
            var $dataParent = {
              value: datas[i].id,
              text: datas[i].name,
              disabled: true,
            };
            this.filter.optionsCategory.push($dataParent);

            var j;
            var datas2 = datas[i].children;
            // console.log(datas2[i].name)
            for (j = 0; j < datas2.length; j++) {
              var $dataChildren = {
                value: datas2[j].id,
                text: datas[i].name + " - " + datas2[j].name,
              };
              this.filter.optionsCategory.push($dataChildren);
            }
          }
        }
        // console.log(this.filter.optionsCategory)
      });
    },
    deleteVendor($id) {
      this.$http
        .post("vendor/" + $id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Vendor has been deleted",
              variant: "danger",
            },
          });
          location.href = "/product/vendor";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style>
#category-vendor option:disabled {
  font-weight: bolder;
  /* color: darkgray; */
  /* text-align: center; */
  background-color: #ebebeb;
}
#category-vendor option:not([disabled]) {
  margin-left: 40px !important;
  transform: scaleX(200px);
}
</style>